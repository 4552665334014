
import React, {Fragment, useState} from 'react';
import Carousel, {slidesToShowPlugin} from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import {slide as Menu} from 'react-burger-menu'

import data from './stories.json';
import './style.css';

const bodyStyle = {
    backgroundColor: 'black'
};
const plugins = ['fastSwipe', {
    resolve: slidesToShowPlugin,
    options: {
        numberOfSlides: 1
    }
}];

const Image = props => {
    const {_id, children, image} = props;

    return (
        <div className="content">
            {children}
            <img src={`/images/${_id}/${image}`} className="image" alt="" />
        </div>
    );
};

function App() {
    const [currentStory, setCurrentStory] = useState(3);
    const [currentSlide, setCurrentSlide] = useState(0);
    const {_id, pages = [], title} = data[currentStory];
    const Tag = ({tagName, children, ...props}) => React.createElement(tagName, props, children);

    const onChange = value => {
        setCurrentSlide(value);
    };

    const onClick = idx => {
        setCurrentStory(idx);
        setCurrentSlide(0);
    };

    return (
        <div style={{bodyStyle}}>
            <Menu>
                {data.map((story, idx) => {
                    const {_id, pages, title} = story;
                    const [{image}] = pages;
                    const style = {
                        backgroundImage: `url(/images/${_id}/${image})`
                    }

                    return (
                        <span className="menu-item" key={idx} onClick={onClick.bind(null, idx)}>
                            <span style={style} className="thumbnail" />
                            {title}
                        </span>
                    );
                })}
            </Menu>
            <Carousel plugins={plugins} value={currentSlide} onChange={onChange}>
                {pages.map((page, idx) => {
                    const {content, contentTag, direction, image, type} = page;
                    const Text = (
                        <div className="text">
                            <Tag tagName={contentTag}>
                                {content.map((line, idx) => {
                                    return (
                                        <Fragment key={idx}>
                                            {line}
                                            {idx !== content.length - 1 && 
                                                <Fragment>
                                                    <br />
                                                    <br />
                                                </Fragment>
                                            }
                                        </Fragment>
                                    );
                                })}
                            </Tag>
                        </div>
                    );
                    return (
                        <Fragment key={`${_id}_${idx}`}>
                            {type !== 'cover' && (
                                <div className="split-div">
                                    {direction === 'left' ? <Image _id={_id} image={image} /> : Text}
                                    <div className="frame" />
                                    <div className="divider" />
                                    {direction === 'right' ? <Image _id={_id} image={image} /> : Text}
                                </div>
                            )}
                            {type === 'cover' && (
                                <div className="cover-div">
                                    <div className="frame" />
                                    <Image _id={_id} image={image}>
                                        <div className="cover-text">
                                            <Tag tagName={contentTag}>
                                                {content.join('')}
                                            </Tag>
                                        </div>
                                    </Image>
                                </div>
                            )}
                        </Fragment>
                    );
                })}
            </Carousel>
        </div>
    );
}

export default App;
